import * as moment from 'moment';
import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {FileSizePipe} from '@Common/widgets/pipes/file-size';

@Injectable({
  providedIn: 'root'
})
export class FilesUtils {
  constructor(@Inject(LOCALE_ID) private locale: string) {
  }
  private imageExtensions: Map<string, string> = new Map<string, string>([
    ['xls', 'fa-file-excel-o'],
    ['xlsx', 'fa-file-excel-o'],
    ['ods', 'fa-file-excel-o'],
    ['doc', 'fa-file-word-o'],
    ['docx', 'fa-file-word-o'],
    ['odt', 'fa-file-word-o'],
    ['pdf', 'fa-file-pdf-o'],
    ['pptx', 'fa-file-powerpoint-o'],
    ['ppt', 'fa-file-powerpoint-o'],
    ['odp', 'fa-file-powerpoint-o'],
    ['png', 'fa-image'],
    ['jpg', 'fa-image'],
    ['jpeg', 'fa-image'],
    ['gif', 'fa-image'],
  ]);

  public getPictoFromExtension(file, propertyFileName = 'filename') {
    const defaultPicto = 'fa-file-o';

    if (file && file[propertyFileName]) {
      const ext = file[propertyFileName].split('.').pop();
      if (this.imageExtensions.has(ext)) {
        return this.imageExtensions.get(ext);
      }
    }

    return defaultPicto;
  }
  public sizeFile(size, graduate = false) {
    if (graduate) {
      const fp = new FileSizePipe(this.locale);
      return fp.transform(size, 2, this.locale);
    }
    return `${size / 1000000}`;
  }
  /**
   * Obtient un nom de fichier avec la date du jour ainsi que l'heure
   *
   * @param filename nom du fichier
   * @param {string} ext extension à ajouter, par défaut pdf
   * @return {string} le filename_YYYYMMDD_HH:mm:ss.ext
   */
  public getDateOfTheDay(filename, ext = 'pdf') {
    const dte = moment().format('YYYYMMDD_HH:mm:ss');

    let nameFile = '';
    if (filename) {
      nameFile = `${filename}_${dte}.${ext}`;
    } else {
      nameFile = `PROJETS_${dte}.${ext}`;
    }
    return nameFile;
  }

  /**
   * Retire les accents
   *
   * @param value
   * @return {any}
   */
  public removeDiatrics(value) {
    const accent = [
      /[\300-\306]/g, /[\340-\346]/g, // A, a
      /[\310-\313]/g, /[\350-\353]/g, // E, e
      /[\314-\317]/g, /[\354-\357]/g, // I, i
      /[\322-\330]/g, /[\362-\370]/g, // O, o
      /[\331-\334]/g, /[\371-\374]/g, // U, u
      /[\321]/g, /[\361]/g, // N, n
      /[\307]/g, /[\347]/g, // C, c
    ];
    const noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

    let str = value;
    for (let i = 0; i < accent.length; i++) {
      str = str.replace(accent[i], noaccent[i]);
    }

    return str;
  }

  /**
   * "slugify" un texte : retire accents, espaces etc (tout ce qui n'est pas A-Z a-Z 0-9) d'une chaine de caractères
   * par défaut en MAJUSCULE
   * @param value
   * @param {boolean} lowerCase si retour souhaité en minuscule
   * @return {any}
   */
  public slugify(value, lowerCase = false) {
    if (!value)
      return '';

    let slug = this.removeDiatrics(value);
    if (lowerCase) {
      slug = value.toLowerCase().trim();
    } else {
      slug = value.toUpperCase().trim();
    }
    slug = slug.replace(/[^a-zA-Z0-9\s-]/g, ' ');
    slug = slug.replace(/[\s-]+/g, '-');

    return slug;
  }
}
