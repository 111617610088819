import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {User} from './user.model';
import {environment} from '@Env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '../../jwt';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user: User;
  private url = `${environment.baseUrl}/api-token-auth/`;
  private userSource = new ReplaySubject<User>(1);

  userActivate$ = this.userSource.asObservable();

  _redirectUrl = null;

  set redirectUrl(url) {
    this._redirectUrl = url;
  }

  get redirectUrl() {
    return this._redirectUrl;
  }

  constructor(private http: HttpClient, private jwtService: JwtHelperService) {

  }

  login(user: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.post(this.url, {
      username: user.username,
      password: user.password
    }, {headers: headers});
  }

  authenticated(data: any) {
    console.log('** authenticated');
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data.user));
    this.userSource.next(data.user as User);
  }

  isAuthenticated() {
    return !this.jwtService.isTokenExpired();
  }

  loggedIn() {
    return !this.jwtService.isTokenExpired();
  }

  isTokenExpired() {
    return this.jwtService.isTokenExpired();
  }

  getTokenExpiredDate() {
    return this.jwtService.getTokenExpirationDate();
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  getToken() {
    return localStorage.getItem('token') || '';
  }

  getUser(): User {
    return <User>JSON.parse(localStorage.getItem('user'));
  }
  refreshToken(token) {
    const url = `${environment.baseUrl}/api-token-refresh/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.post(url, { token: token }, {headers: headers});
  }
  sendUser() {
    console.log('** sendUser');
    this.user = <User>JSON.parse(localStorage.getItem('user'));
    this.userSource.next(this.user);
  }

  impersonnate(user) {
    const url = `${environment.baseUrl}/api-token-impersonate/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return this.http.post(url, { user: user }, {headers: headers});
  }
}



