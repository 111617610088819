export const ContrastConst = {
  Custom: 'custom-contrast'
};

export enum FontsType {
  Default = 'default-font',
  Dys = 'dys-font'
}

export enum InterlignageType {
  Default = 'default-spacing',
  Dys = 'dys-spacing'
}

export enum ColorsType {
  Default = 'Défaut',
  Renforcer = 'Renforcer',
  Inverse = 'Inverser'
}

export enum ContrastClass {
  Default = '',
  Renforcer = 'renforcer-theme',
  Inverse = 'inverser-theme'
}

// Mappage des types de couleurs avec leurs classes de contraste correspondantes
export const ColorsValues = {
  [ColorsType.Default]: { contrastClass: ContrastClass.Default },
  [ColorsType.Renforcer]: { contrastClass: ContrastClass.Renforcer },
  [ColorsType.Inverse]: { contrastClass: ContrastClass.Inverse }
};
