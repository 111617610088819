import { InjectionToken } from '@angular/core';

export class ImagePdfViewerConfig {
  width?: number;
  height?: number;
  bgStyle?: string;
  scaleStep?: number;
  rotateStepper?: boolean;
  buttonStyle?: ButtonStyle;
  loadingMessage?: string;
  tooltips?: {
    enabled?: boolean,
    bgStyle?: string,
    bgAlpha?: number,
    textStyle?: string,
    textAlpha?: number,
    padding?: number,
    radius?: number
  };
  nextPageButton?: ButtonConfig;
  beforePageButton?: ButtonConfig;
  zoomOutButton?: ButtonConfig;
  zoomInButton?: ButtonConfig;
  rotateLeftButton?: ButtonConfig;
  rotateRightButton?: ButtonConfig;
  resetButton?: ButtonConfig;
  selectModeButton?: ButtonConfig;
}

export interface ButtonStyle {
  iconFontFamily?: string;
  alpha?: number;
  hoverAlpha?: number;
  bgStyle?: string;
  iconStyle?: string;
  borderStyle?: string;
  borderWidth?: number;
}

export interface ButtonConfig {
  icon?: string;
  tooltip?: string;
  sortId?: number;
  show?: boolean;
}

export interface Point {
  x: number;
  y: number;
}

export function createButtonConfig(icon?: string, tooltip?: string, sortId: number = 0, show: boolean = true) {
  return { icon: icon, tooltip: tooltip, sortId: sortId, show: show };
}

export function createButtonStyle(
  iconFontFamily?: string,
  alpha?: number,
  hoverAlpha?: number,
  bgStyle?: string,
  iconStyle?: string,
  borderStyle?: string,
  borderWidth?: number) {
  return {
    iconFontFamily: iconFontFamily,
    alpha: alpha,
    hoverAlpha: hoverAlpha,
    bgStyle: bgStyle,
    iconStyle: iconStyle,
    borderStyle: borderStyle,
    borderWidth: borderWidth,
  };
}

export const IMAGEVIEWER_CONFIG = new InjectionToken<ImagePdfViewerConfig>('imageviewer.config');

export let IMAGEVIEWER_CONFIG_DEFAULT: ImagePdfViewerConfig = {
  width: 800, // component default width
  height: 600, // component default height
  bgStyle: '#ECEFF1', // component background style
  scaleStep: 0.1, // zoom scale step (using the zoom in/out buttons)
  rotateStepper: false,
  loadingMessage: 'Chargement...',
  buttonStyle: {
    iconFontFamily: 'Material Icons', // font used to render the button icons
    alpha: 0.5, // buttons' transparence value
    hoverAlpha: 0.7, // buttons' transparence value when mouse is over
    bgStyle: '#000000', //  buttons' background style
    iconStyle: '#ffffff', // buttons' icon colors
    borderStyle: '#000000', // buttons' border style
    borderWidth: 0 // buttons' border width (0 == disabled)
  },
  tooltips: {
    enabled: true, // enable or disable tooltips for buttons
    bgStyle: '#000000', // tooltip background style
    bgAlpha: 0.5, // tooltip background transparence
    textStyle: '#ffffff', // tooltip's text style
    textAlpha: 0.9, // tooltip's text transparence
    padding: 15, // tooltip padding
    radius: 20 // tooltip border radius
  },
  nextPageButton: createButtonConfig(String.fromCharCode(0xE409), 'Page suivante', 0),
  beforePageButton: createButtonConfig(String.fromCharCode(0xE408), 'Page précédente', 1),
  zoomOutButton: createButtonConfig(String.fromCharCode(0xE900), 'Dézoomer', 0),
  zoomInButton: createButtonConfig(String.fromCharCode(0xE8FF), 'Agrandir', 1),
  rotateLeftButton: createButtonConfig(String.fromCharCode(0xE419), 'Pivoter à gauche', 2),
  rotateRightButton: createButtonConfig(String.fromCharCode(0xE41A), 'Pivoter à droite', 3),
  resetButton: createButtonConfig(String.fromCharCode(0xE863), 'Réinitialiser', 4),
  // selectModeButton: createButtonConfig(String.fromCharCode(0xEF52), 'Select mode toggle', 5)
};
