<ng-container *ngIf="pager.totalPages > 1">
  <div fxLayout="column" fxFlex="90">
    <div *ngIf="displayCountPages" class="pagerListCount" fxLayoutAlign="end">
      <span class="label">{{pager.totalItemsView}} / {{pager.totalItems}}</span>
    </div>

    <div class="pagination mat-primary">
      <div>
        <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
          <li>
            <button [disabled]="pager.currentPage === 1" mat-raised-button color="{{colorButton}}"
                    (click)="setPage(1)" matTooltip="aller à la 1ère page">&lt;&lt;</button>
          </li>
          <li>
            <button [disabled]="pager.currentPage === 1" mat-raised-button color="{{colorButton}}"
                    (click)="setPage(pager.currentPage - 1)" matTooltip="page précédente">&lt;</button>
          </li>
          <li *ngFor="let page of pager.pages" [ngClass]="{active: pager.currentPage === page}">
            <button mat-raised-button color="{{colorButton}}"
                    (click)="setPage(page)" matTooltip="aller à la page {{page}}">{{page}}</button>
          </li>
          <li>
            <button [disabled]="pager.currentPage === pager.totalPages" mat-raised-button color="{{colorButton}}"
                    (click)="setPage(pager.currentPage + 1)" matTooltip="page suivante">&gt;</button>
          </li>
          <li>
            <button [disabled]="pager.currentPage === pager.totalPages" mat-raised-button color="{{colorButton}}"
                    (click)="setPage(pager.totalPages)" matTooltip="aller à la dernière page">&gt;&gt;</button>
          </li>
        </ul>
      </div>

      <mat-form-field *ngIf="numberItemsChoices.length > 0" fxFlex="10">
        <mat-select placeholder="nombre d'éléments par page"
                    (selectionChange)="onSelectMaxItems($event)"
                    [value]="selectedMaxItems"
                    style="font-size: 0.8em"
                    matTooltip="nombre d'éléments par page">
          <mat-option *ngFor="let v of numberItemsChoices" [value]="v">{{v}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</ng-container>
