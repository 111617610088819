import {
  Component, Input, ViewChild, ComponentFactoryResolver, OnDestroy,
  OnChanges, SimpleChanges, OnInit, Optional, Output, EventEmitter
} from '@angular/core';
import {ComponentItem} from "@Common/home/navbar/dynamic-core-components/component-item";

import {HostViewHostDirective} from "@Common/widgets/directives/core/host-view-container.directive";
import { MatDialogRef } from "@angular/material/dialog";
import {DialogPosition} from '@angular/material/dialog';
import {HeaderComponent} from "@Common/widgets/general/data-table/interfaces/component-header-interface.component";
import {Subscription} from "rxjs";

/**
 * Component d'injection du component de ColumnDataTable.headerComponent
 * Se charge d'instancier le component à mettre et de l'activer
 * Placé dans data-table.component.html / <mat-header-cell>
 */
@Component({
  selector: 'data-table-header-component',
  templateUrl: `./data-table-header.component.html`,
  styles: []
})
export class DataTableHeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() component: ComponentItem;
  @Input() position: DialogPosition;
  @Output() onClick = new EventEmitter();

  @ViewChild(HostViewHostDirective, { static: true }) hostViewContainer: HostViewHostDirective;

  subscription: Subscription;

  componentInitialized = false;

  constructor(@Optional() public dialogRef: MatDialogRef<any>,
              private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit() {
    this.loadComponent();
    this.changePosition();
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Fermeture de la dialog
   */
  close() {
    if (this.dialogRef) {
      this.componentInitialized = false;
      this.dialogRef.close();
    }
  }

  /**
   * Positionnement avec les coordonnées données
   */
  changePosition() {
    if (this.dialogRef) {
      this.dialogRef.updatePosition(this.position);
    }
  }

  /**
   * Initialisation du component et chargement
   */
  loadComponent() {
    if (this.component && this.hostViewContainer && this.hostViewContainer.viewContainerRef) {
      const viewContainerRef = this.hostViewContainer.viewContainerRef;
      if (viewContainerRef) {
        viewContainerRef.clear();

        const adItem = this.component;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem.component);
        const componentRef = viewContainerRef.createComponent(componentFactory);

        (<HeaderComponent>componentRef.instance).name = adItem.name;
        (<HeaderComponent>componentRef.instance).title = adItem.title;

        (<HeaderComponent>componentRef.instance).condition = adItem.condition;

        (<HeaderComponent>componentRef.instance).data = adItem.data;
        (<HeaderComponent>componentRef.instance).dataDefault = adItem.dataDefault;

        (<HeaderComponent>componentRef.instance).subject = adItem.subject;
        (<HeaderComponent>componentRef.instance).subject$ = adItem.subject$;

        (<HeaderComponent>componentRef.instance).automaticCloseOnClick = adItem.automaticCloseOnClick;

        this.subscription = (<HeaderComponent>componentRef.instance).subject$.subscribe((d) => {
              if (d &&
                (<HeaderComponent>componentRef.instance).automaticCloseOnClick &&
                this.componentInitialized) {
                this.onClick.emit(d);
              }
            });

        this.componentInitialized = true;

        componentRef.onDestroy(() =>  {
          if (this.subscription) {
            this.subscription.unsubscribe();
            this.componentInitialized = false;
          }
        });

        componentRef.changeDetectorRef.detectChanges();
      }
    }
  }
}

