import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AccessibilityComponent} from "@Common/widgets/accessibility/accessibility.component";
import {ConfigDialog} from "@Common/widgets/accessibility/config-modal/config-dialog";
import {MatAngularModule} from "@Common/mat-angular.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAngularModule
  ],
  declarations: [
    AccessibilityComponent,
    ConfigDialog
  ],
  exports: [
    AccessibilityComponent,
    ConfigDialog
  ],
  providers: []
})
export class AccessibilityModule { }
