import { Injectable } from '@angular/core';

@Injectable()
export abstract class AppUrlConfig {
  /**
   * Doit être implémenté dans la classe dérivée, l'implémentation est mis dans la partie providers du module
   */
  abstract urls: { baseUrl: string, list: {}};

  /**
   *
   * @param name
   * @param urlsCustom : pour remplacer la propriété urls
   * @returns {string}
   */
  // url(name, parm1?, parm2?, parm3?, urlsCustom?) {
  url(name, urlsCustom?) {
    let url = '';

    const urls = urlsCustom ? urlsCustom : this.urls;

    if(urlsCustom) {
      url = urls.baseUrl + urlsCustom.list[name];
    } else {
      url = urls.baseUrl + urls.list[name];
    }

    return url;
  }


  constructor() {

  }
}

