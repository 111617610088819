<div fxLayout="column">
  <div fxFlexAlign="end">
    <button  mat-icon-button (click)="close()">
      <mat-icon [inline]="true">close</mat-icon>
    </button>
  </div>
  <div>
    <ng-container host-view-container></ng-container>
  </div>
</div>
