import { Injectable } from '@angular/core';
import {DaoGeneric} from '@Services/base/daoService';
import {HttpClient} from '@angular/common/http';
import {environment} from '@Env/environment';
import {ApplicationPortail} from "./application-portail.model";

@Injectable({
  providedIn: 'root'
})
export class ApplicationPortailService extends DaoGeneric<ApplicationPortail> {
  private url = `${environment.baseUrl}/portail/application/`;


  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  getRootUrl(): string {
    return this.url;
  }

}
