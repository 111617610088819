export class DialogData {
  title = 'Confirmation';
  subtitle = '';
  message = 'Etes-vous sur ?';

  iconType = ''; // '' | warning |...
  buttonTextTrue = 'OUI';
  buttonTextFalse = 'NON';
  buttonColorTrue = 'warn';
  buttonColorFalse = '';
  buttonJustify = 'end'; // start | center | end

  content = false; // peut-il avoir du contenu à saisir ?
  contentElement = 'textarea'; // type d'input : textarea, input
  contentFormOutline = false; // formulaire outline oui/non
  contentType = 'text'; // type de l'input le cas échéant
  contentMinLength = 0; // le contenu à saisir a-t-il un minimum de caractère ?
  contentMaxLength;
  contentPlaceHolder = ''; // le placeholder du contenu à saisir
  contentData = ''; // la chaine pré-saisie
  actionType = {'information': 1, 'validation': 2}; // types d'actions possible
  action = this.actionType.validation; // action choisie
  disableButton = true; // config statut button envoie
  upperCaseFirstLetter = true;
}
