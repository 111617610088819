import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: 'app-menuicon',
  templateUrl: './menuicon.component.html',
  styleUrls: ['./menuicon.component.css']
})
export class MenuIconComponent implements OnInit {
  @Input() icon: string;

  patternFaIcon = new RegExp("^fa\-(.+)$");

  ngOnInit() {}

  assertNullOrEmpty() {
    if(! this.icon || this.icon === '')
      return false;

    return true;
  }

  isFaIcon() {
    return this.assertNullOrEmpty() && this.patternFaIcon.test(this.icon);
  }

  isMatIcon() {
    return this.assertNullOrEmpty() && ! this.patternFaIcon.test(this.icon);
  }
}
