import {Type} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ColumnComponent} from '@Common/widgets/general/data-table';

export class ColumnComponentItem implements ColumnComponent {
  public name: string;
  public input: any;
  public column: string;
  constructor(public component: Type<ColumnComponent>, public data: any) {}
}
