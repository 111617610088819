import * as _ from 'lodash';
import { Injectable } from "@angular/core";

/**
 * Calcul pagination
 *
 * https://github.com/cornflourblue/angular2-pagination-example
 */
@Injectable()
export class PagerService {
  getPager(totalItems: number, totalView: number = 0, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = _.range(startPage, endPage + 1);

    let totalItemsCurrentPage = 0;

    if(currentPage !== totalPages){
      totalItemsCurrentPage = pageSize * currentPage;
    } else {
      totalItemsCurrentPage = (pageSize * (totalPages-1)) + totalView;
    }

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      totalItemsView: totalItemsCurrentPage,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
}
