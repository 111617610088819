import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ApplicationPortail } from "../common/services/application/application-portail.model";
import {ThemeModel, ThemesApplications} from "../common/services/theme/theme.model";
import {rolesPortail} from "../common/enums/roles-portail";
import {IndividuService} from "@Common/core/services";
import {SubSink} from "@Common/core/utils/subsink";
import {ApplicationPortailService} from "../common/services/application/application-portail.service";
import {ThemeService} from "../common/services/theme/theme.service";

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit, OnDestroy {
  applications: ApplicationPortail[] = [];
  themes: ThemeModel[] = [];
  themesApplications: ThemesApplications[] = [];
  isAdmin = false;
  SubSink = new SubSink();
  @Input() cssClass: string;

  constructor(private individuService: IndividuService,
              private appSvc: ApplicationPortailService,
              private themeSvc: ThemeService ) { }

  ngOnInit(): void {
    this.SubSink.sink = this.individuService.individu$.subscribe(_individu => {
      if (this.individuService.hasRole(_individu, rolesPortail.admin)) {
        this.isAdmin = true;
        this._getThemes();
      }
    });
  }

  ngOnDestroy(): void {
    this.SubSink.unsubscribe();
  }

  private _getApplications(): void{
    this.SubSink.sink = this.appSvc.listAllItems().subscribe((_data) => {
      this.applications = _data.list;
      this._initApplicationsThemes();
    });
    this._initApplicationsThemes();
  }

  private _getThemes(): void {
    this.SubSink.sink = this.themeSvc.listAllItems().subscribe((_themes) => {
      this.themes = _themes.list;
      this._getApplications();
    });
    this._getApplications();
  }

  private _initApplicationsThemes(): void {
    const filteredApplications = this.isAdmin ?
      this.applications :
      this.applications.filter(application => {
        const theme = this.themes.find(theme => theme.id === application.theme);
        return theme && theme.is_public;
      });
    this.themesApplications = this.themes.map(theme => ({
      theme: theme,
      applications: filteredApplications.filter(application => theme.id === application.theme)
    }));
  }

}
