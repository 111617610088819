import {Injectable} from '@angular/core';
import {ContrastOptions, FontsOptions, InterlignageOptions} from "@Common/widgets/accessibility/services/colors-model";
import {
  ContrastClass,
  ContrastConst,
  FontsType,
  InterlignageType
} from "@Common/widgets/accessibility/services/colors-enums";

@Injectable({
  providedIn: 'root'
})
export class AccessibilityService {
  bodyClasses = document.body.classList;

  setColors(contrastColors: ContrastOptions) {
    this.toggleContrastClass(contrastColors.contrastClass);
  }

  setFontFamily(selectedFontFamily: FontsOptions) {
    this.bodyClasses.remove(FontsType.Default, FontsType.Dys);
    if (selectedFontFamily) {
      this.bodyClasses.add(selectedFontFamily.fontClass);
    }
  }

  setInterlignage(selectedInterlignage: InterlignageOptions) {
    this.bodyClasses.remove(InterlignageType.Default, InterlignageType.Dys);
    if (selectedInterlignage) {
      this.bodyClasses.add(selectedInterlignage.interlignageClass);
    }
  }

  private toggleContrastClass(contrastClass: string): void {
    const classesToRemove = [ContrastConst.Custom, ContrastClass.Renforcer, ContrastClass.Inverse];
    const classesToAdd = contrastClass ? [ContrastConst.Custom, contrastClass] : [];

    this.bodyClasses.remove(...classesToRemove);
    this.bodyClasses.add(...classesToAdd);
  }

}
