import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {Message} from "@Services/message/message.model";
import {Login} from "@Common/core/login/login.model";
import {SubSink} from "@Common/core/utils/subsink";
import {AuthService} from "@Services/auth";
import {Router} from "@angular/router";
import {finalize, switchMap} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {
  AccessTo,
  AccessToApplication,
  accessToApplication,
  IndividuExterneAskResetPwd, IndividuExternePasswordService,
  IndividuExterneService
} from "@Common/core/services";
import {of} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {DialogData, DialogValidationComponent} from "@Common/widgets/general/dialog-validation";


@Component({
  selector: 'app-external-login',
  templateUrl: './external-login.component.html',
  styleUrls: ['./external-login.component.scss'],
})
export class ExternalLoginComponent implements OnInit, OnDestroy {
  @Input() parentUrl;
  message: Message;
  modeAnonyme = false;
  model = <Login>{};
  loading = false;
  subSink = new SubSink();
  applicationName = '';
  accessToApplication = AccessToApplication;
  constructor(
    private authService: AuthService,
    private router: Router,
    private individuExtSvc: IndividuExterneService,
    private individuExtPwdSvc: IndividuExternePasswordService,
    private dialog: MatDialog,
    @Inject(accessToApplication) public application: AccessToApplication,
  ) {
  }

  ngOnInit() {
    if (this.authService.loggedIn()) {
      this.router.navigate([this.parentUrl]);
    }
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  downloadFile() {
    const relativeFilePath = './assets/ater/MOP-ATER-CREATION-IDENTIFIANT.pdf';
    const fileUrl = new URL(relativeFilePath, window.location.origin);

    const link = document.createElement('a');
    link.href = fileUrl.href;
    link.target = '_blank';
    link.download = 'MOP-ATER-CREATION-IDENTIFIANT.pdf';

    link.click();
  }

  closeAlert() {
    this.message = null;
  }

  toggleAnonime() {
    this.modeAnonyme = !this.modeAnonyme;
  }

  canValidate() {
    if (this.loading) {
      return false;
    }
    return !(!this.model.username || !this.model.password);
  }

  login() {
    this.loading = true;
    this.subSink.sink = this.authService.login(this.model)
      .pipe(
        switchMap(
          (data) => {
            if (data.token) {
              this.authService.authenticated(data);
              return this.individuExtSvc.canConnectToApplication();
            }
            return of({is_active: false});
          }
        ),
        finalize(() => this.loading = false)
      )
      .subscribe((accesTo: AccessTo) => {
          console.log('login external: ', accesTo);
          if (!accesTo.is_active) {
            this.message = {
              message: 'Vous n\'avez pas le droit de vous connecter sur cette application',
              label: '',
              color: 'red',
              icon: 'error'
            };
            this.authService.logout();
          } else {
            this.router.navigate([this.parentUrl]);
          }
        },
        error => {
          this.loading = false;
          if (error instanceof HttpErrorResponse && error.status === 400) {
            // let message =  error.error.non_field_errors[0] || error.error['password'] || error.error['username'];
            const message = error.error.non_field_errors[0];
            this.message = {
              message: message,
              label: '',
              color: 'red',
              icon: 'error'
            };
          } else {
            throw error;
          }
        }
      );
  }

  loginAnonym() {
    this.router.navigate(['/public']);
  }

  /**
   * Reset mot de passe : envoie réinit mot de passe selon login ou mail
   * On force le logout pour supprimer token et user pour éviter un 401 si le token JWT était encore présent et obsolète (durée dépassée par ex)
   */
  resetPwd() {
    this.authService.logout();
    console.log('-- reset pwd -- ', window.location.href);
    const data = new DialogData();

    data.title = `Réinitialisation du mot de passe`;
    data.iconType = 'lock_open';
    data.message = `Veuillez saisir l\'adresse électronique ou votre identifiant qui est utilisé pour vous connecter.<br/>Un courriel va vous être envoyé avec les informations pour ré-initialiser votre mot de passe.`;
    data.content = true;
    data.contentElement = 'input';
    data.contentMinLength = 5;
    data.contentPlaceHolder = `Adresse électronique ou Identifiant`;
    data.buttonTextTrue = 'Envoyer';
    data.buttonTextFalse = 'Annuler';
    data.buttonColorTrue = 'primary';
    data.upperCaseFirstLetter = false;

    const dialogRef = this.dialog.open(DialogValidationComponent, {data: data});
    dialogRef.disableClose = true;
    dialogRef.updatePosition({top: '50px'});
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirm) {
        const mail = result.contentData;
        const link = window.location.href;  // http://localhost:4200/login
        const param: IndividuExterneAskResetPwd = {
          username: mail, redirect_url: link, application: this.application,
        };
        this.individuExtPwdSvc.askReset(param).subscribe(_result => console.log(_result));
      }
    });
  }
}
