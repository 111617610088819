import {Component, OnDestroy, OnInit} from '@angular/core';
import {Menu} from "@Common/home/models/menu.model";
import {IndividuService} from "@Common/core/services";
import {rolesPortail} from "./common/enums/roles-portail";
import {SubSink} from "@Common/core/utils/subsink";

@Component({
  selector: 'app-re-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'Portail des applications de Paris 8';
  menu: Menu[] = [];
  subSink = new SubSink();

  constructor(private individuService: IndividuService) {}
  ngOnInit(): void {
    this.menu = [];
    this.subSink.sink = this.individuService.individu$.subscribe(_individu => {
      if (this.individuService.hasRole(_individu, rolesPortail.admin)) {
        this.menu = [<Menu>{url: '/admin', titre: 'Admin', nav: false, icon: 'settings'}];
      }
    });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

}
