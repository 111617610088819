import {Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';
@Directive({
  selector: '[UpperCaseFirstLetter]',
  host: {
    '(input)': 'toUpperCaseFirstLetter($event.target.value)',
  }
})
export class UpperCaseFirstLetterTextDirective  {
  @Input('UpperCaseFirstLetter') allowUpperCase: boolean;
  @Output() ngModelChange = new EventEmitter();
  @Output() fxChange = new EventEmitter();

  constructor(private ref: ElementRef) {
  }

  toUpperCaseFirstLetter(value: any) {
    if (this.allowUpperCase && value.length > 0) {
      this.ref.nativeElement.value = `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
      this.ngModelChange.emit(this.ref.nativeElement.value);
      this.fxChange.emit(this.ref.nativeElement.value);
    }
  }

}
