import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ConfigDialog } from '@Common/widgets/accessibility/config-modal/config-dialog';
import { AccessibilityService } from '@Common/widgets/accessibility/services/accessibility.service';

@Component({
  selector: 'app-accessibility',
  templateUrl: './accessibility.component.html',
  styleUrls: ['./accessibility.component.scss']
})
export class AccessibilityComponent implements OnInit, OnDestroy {
  private navigationSubscription;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private contrastService: AccessibilityService
  ) {
    this.navigationSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => this.loadSettings());
  }

  ngOnInit(): void {
    this.loadSettings();
  }

  private loadSettings(): void {
    const configs = JSON.parse(localStorage.getItem('configs'));
    if (configs) {
      this.applyAccessibilitySettings(configs);
    }
  }

  private applyAccessibilitySettings(configs): void {
    this.contrastService.setFontFamily(configs.selectedFontClass);
    this.contrastService.setColors(configs.contrast);
    this.contrastService.setInterlignage(configs.selectedInterlignageClass);
  }

  openDialog(): void {
    const configs = JSON.parse(localStorage.getItem('configs'));
    const dialogRef = this.dialog.open(ConfigDialog, {
      data: configs,
      disableClose: true,
      position: { top: '50px' },
      width: '58.750em'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isNewConfigs) {
        localStorage.setItem('configs', JSON.stringify(result));
        this.applyAccessibilitySettings(result);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
