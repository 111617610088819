import { ErrorHandler, Injectable } from '@angular/core';
import * as Raven from 'raven-js';

import { MessageService } from './core/services/message/message.service';
import { environment } from '../../environments/environment';


if (environment.production) {
  Raven
    .config(environment.urlSentry)
    .install();

}

@Injectable({   providedIn: 'root' })
export class HandlerError implements ErrorHandler {

  constructor(private messageService: MessageService) {

  }

  handleError(error: any): void {
    if (environment.production) {
      Raven.captureException(error.originalError||error);
    } else {
      console.log(error);
    }

    let message = error.message || error.error || error;

    // prise en compte erreurs de champs, par exemple error.reference["erreur sur ce champ"]
    for (var k in message) {
      if (message.hasOwnProperty(k) && Array.isArray(message[k])) {
        if (message[k].every(item => typeof item === "string")) {
          const msgs =  message[k].reduce(function (acc, currentValue, idx) {
            return idx === 0 ? currentValue : `${acc} ${currentValue}`;
          }, '');

          message = `${k} : ${msgs}`;
          console.log('msgs : ', msgs);
        }

        // console.log(`Key is ${k}, value is ${message[k]}`);
      }
    }


    // Http error from django
    if('detail' in error) {
      message = error.detail;
    }

    if('error' in error && 'detail' in error.error) {
      message = error.error.detail;
    }

    /**
     * Http error field from django
     */
    if('non_field_errors' in error) {
      message = error.non_field_errors;
    }

    if('name' in error) {
      message = error.name[0];
    }

    console.dir(error);

    this.messageService.sendMessage({label: "Erreur", message: message, color: 'red', icon: 'error'});
  }

}
