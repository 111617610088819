import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagepdfviewerComponent } from './imagepdfviewer/imagepdfviewer.component';
import {IMAGEVIEWER_CONFIG, IMAGEVIEWER_CONFIG_DEFAULT} from '@Common/widgets/general/imagepdfviewer/imagepdfviewer/imagepdfviewer.config';
import {HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';


export class HammerConfig extends HammerGestureConfig {
  // This configuration makes the (pan) event trigger after 1px of movement instead of 10px. It makes the UX more responsive.
  // override overrides = {pan: {threshold: 1}};
  // @ts-ignore
  overrides = {pan: {threshold: 1}};
}

@NgModule({
  declarations: [
    ImagepdfviewerComponent
  ],
  providers: [
    { provide: IMAGEVIEWER_CONFIG, useValue: IMAGEVIEWER_CONFIG_DEFAULT },
    {provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig}
  ],
  imports: [
    CommonModule,
    HammerModule,
  ],
  exports: [
    ImagepdfviewerComponent
  ]
})
export class ImagepdfviewerModule { }
