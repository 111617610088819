<!--<mat-card>
  <mat-card-content>
    <button mat-raised-button (click)="changeClass('theme__1')">Theme 1</button>&nbsp;
    <button mat-raised-button (click)="changeClass('theme__2')">Theme 2</button>&nbsp;
    <button mat-raised-button (click)="changeClass('theme__3')">Theme 3</button>&nbsp;
    <button mat-raised-button (click)="changeClass('theme__4')">Theme 4</button>&nbsp;
    <button mat-raised-button (click)="changeClass('theme__4__fav')">Theme 4 fav</button>&nbsp;
    <button mat-raised-button (click)="changeClass('theme__5')">Theme 5</button>
  </mat-card-content>
</mat-card>-->
<app-favoris [cssClass]="cssClass"></app-favoris>
<app-applications [cssClass]="cssClass"></app-applications>
