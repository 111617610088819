import { Injectable }           from '@angular/core';
import {ReplaySubject} from "rxjs";
import {ComponentItem} from "../../../home/navbar/dynamic-core-components/component-item";

@Injectable({   providedIn: 'root' })
export class ComponentsService {
  private componentsSource = new ReplaySubject<ComponentItem[]>(1);
  private components: ComponentItem[];

  componentActivate$ = this.componentsSource.asObservable();

  /**
   * Set des components à afficher
   * @param {ComponentItem[]} components
   */
  setComponents(components: ComponentItem[]) {
    this.components = components;
    this.componentsSource.next(components);
  }

  /**
   * Obtient un component par son nom
   * @param name
   * @returns {ComponentItem}
   */
  getComponent(name): ComponentItem {
    return this.components.find((obj) => {
      return obj.name === name;
    });
  }
}
