import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { DisciplineSecondDegre } from './discipline-second-degre.model';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class DisciplineSecondDegreService {
  private url = `${environment.baseUrl}/gestion_universite/discipline-second-degre/`;

  constructor(private http: HttpClient) {
  }

  create(disciplineSecondDegre: DisciplineSecondDegre) {
    return this.http.post<DisciplineSecondDegre>(this.url, disciplineSecondDegre).pipe(tap(data => this.list()));
  }

  list() {
      return this.http.get<DisciplineSecondDegre[]>(this.url);
  }
  get(c_disc_second_degre: string) {
    return this.http.get<DisciplineSecondDegre>(`${this.url}${c_disc_second_degre}/`);
  }
  update(disciplineSecondDegre: DisciplineSecondDegre) {
    return this.http.put<DisciplineSecondDegre>(`${this.url}${disciplineSecondDegre.c_disc_second_degre}/`, disciplineSecondDegre);

  }
  delete(disciplineSecondDegre: DisciplineSecondDegre) {
    return this.http.delete<DisciplineSecondDegre>(`${this.url}${disciplineSecondDegre.c_disc_second_degre}/`);

  }
}
