import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccessibilityService } from '@Common/widgets/accessibility/services/accessibility.service';
import {
  ColorsType,
  ColorsValues,
  FontsType,
  InterlignageType
} from "@Common/widgets/accessibility/services/colors-enums";
import {
  ConfigsOptions,
  ContrastOptions,
  FontsOptions,
  InterlignageOptions
} from "@Common/widgets/accessibility/services/colors-model";

@Component({
  selector: 'config-dialog',
  templateUrl: 'config-dialog.html',
})
export class ConfigDialog implements OnInit {
  savedSettings: ContrastOptions;
  selectedSettings: ContrastOptions;
  contrastObjs = Object.keys(ColorsValues).map(key => ({ name: key, value: ColorsValues[key as ColorsType] }));
  selectedFontFamily: FontsOptions;
  selectedInterlignage: InterlignageOptions;
  initialConfigs: ConfigsOptions;
  fontsType = FontsType;
  interlignageType = InterlignageType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfigDialog>,
    private contrastService: AccessibilityService
  ) {
    this.initialConfigs = this.initializeConfigs(data);
    this.savedSettings = this.initialConfigs.contrast;
    this.selectedFontFamily = this.initialConfigs.selectedFontClass;
    this.selectedInterlignage = this.initialConfigs.selectedInterlignageClass;
    this.selectedSettings = this.contrastObjs.find(obj => this.isEqual(obj.value, this.savedSettings))?.value || ColorsValues[ColorsType.Default];
  }

  ngOnInit(): void {}

  initializeConfigs(configs: ConfigsOptions): ConfigsOptions {
    const defaultConfigs: ConfigsOptions = {
      isNewConfigs: false,
      contrast: ColorsValues[ColorsType.Default],
      selectedFontClass: { fontClass: FontsType.Default },
      selectedInterlignageClass: { interlignageClass: InterlignageType.Default }
    };
    return { ...defaultConfigs, ...configs };
  }

  onClassChange(contrastColors: ContrastOptions): void {
    this.savedSettings = contrastColors;
    this.contrastService.setColors(contrastColors);
  }

  onFontFamilyChange(selectedFontFamily: FontsOptions): void {
    this.selectedFontFamily = selectedFontFamily;
    this.contrastService.setFontFamily(selectedFontFamily);
  }

  onFontInterlignageChange(selectedInterlignage: InterlignageOptions): void {
    this.selectedInterlignage = selectedInterlignage;
    this.contrastService.setInterlignage(selectedInterlignage);
  }

  isEqual(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  saveConfigs(): void {
    const settings: ConfigsOptions = {
      isNewConfigs: true,
      contrast: this.savedSettings,
      selectedFontClass: this.selectedFontFamily,
      selectedInterlignageClass: this.selectedInterlignage
    };
    this.dialogRef.close(settings);
  }

  closeDialog(): void {
    this.dialogRef.close(this.initialConfigs);
  }
}
