import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

/**
 * Pour la communication entre data-table.component et les headers components
 * - pour la réinit de tous les filtres (parent => children)
 * - pour la réinit d'un filtre
 * - pour l'état d'un filtre (child => parent)
 */
@Injectable({ providedIn: 'root' })
export class DataTableHeaderCommunicationService {
  private reinitAllFiltersSource = new Subject<boolean>();
  private reinitFilterSource = new Subject<string>();
  private filterUsedSource = new Subject<{name: string, value: any, used: boolean}>();

  sendAllFiltersReinit$ = this.reinitAllFiltersSource.asObservable();
  sendFilterReinit$ = this.reinitFilterSource.asObservable();
  filterUsed$ = this.filterUsedSource.asObservable();

  sendReinitAllFilters(value: boolean) {
    // return Observable.create(observer => {
    //   this.sendAllFiltersReinit$.subscribe(() => {
    //     observer.next(value);
    //     observer.complete();
    //   });
    // });

    this.reinitAllFiltersSource.next(value);
    // this.reinitAllFiltersSource.complete();
  }

  sendReinitFilter(value: string) {
    // return Observable.create(observer => {
    //   this.sendFilterReinit$.subscribe(() => {
    //     observer.next(value);
    //     observer.complete();
    //   });
    // });

    this.reinitFilterSource.next(value);
  }

  filterUsedSub() {
    return new Observable(observer => {
      this.filterUsed$.subscribe((value) => {
        console.log('filterUsedSub ', value);
        observer.next(value);
        observer.complete();
      });
    });
  }

  filterUsed(name: string, value: any, used: boolean = true) {
    this.filterUsedSource.next({name: name, value: value, used: used});
  }
}
