import {Type} from '@angular/core';
import {BehaviorSubject} from "rxjs";

export class ComponentItem {
  public dataDefault: any = null;
  public automaticCloseOnClick: boolean = false;
  public title: string = '';
  public condition: string = '';

  public subject$ = this.subject.asObservable();

  constructor(public component: Type<any>,
              public data: any,
              public name: string = '',
              public subject: BehaviorSubject<any> = new BehaviorSubject(null)
    ) {

  }
}
