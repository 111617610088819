import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuardService } from './auth-guard.service';

import { AuthService } from './auth.service';
@NgModule({
  imports: [
    CommonModule,

  ],
  declarations: [],
  providers: [
    AuthService,

    AuthGuardService
  ]
})
export class AuthCoreModule { }
