import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from "@angular/common";

/*
 * Amélioration de https://gist.github.com/JonCatmull/ecdf9441aaa37336d9ae2c7f9cb7289a
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage:
 *   bytes | fileSize:precision
 * Example:
 *   {{ 1024 |  fileSize}}
 *   formats to: 1 KB
*/
@Pipe({name: 'fileSize'})
export class FileSizePipe implements PipeTransform {
  private _locale;
  private units = [
    'o',
    'Ko',
    'Mo',
    'Go',
    'To',
    'Po'
  ];

  constructor(@Inject(LOCALE_ID)_locale: string) {
  }

  transform(bytes: number = 0, precision: number = 2, locale?: string): string | null {
    locale = locale || this._locale;

    let unit = 0;

    while ( bytes >= 1024 ) {
      bytes /= 1024;
      unit ++;
    }

    const format = `0.${precision}-${precision}`;
    const dp = new DecimalPipe(locale);
    const size = dp.transform(bytes.toString(), format, locale);

    return `${size} ${this.units[unit]}`;
  }
}
