import { Injectable } from '@angular/core';

import { environment } from '@Env/environment';
import { TemplateModel } from './template-model.model';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class TemplateModelService {
  private url = `${environment.baseUrl}/core/template_model/`;
  private urlImpression = `${environment.baseUrl}/core/template-models/`;

  constructor(private http: HttpClient) {
  }

  create(templateModel: TemplateModel) {
    return this.http.post<TemplateModel>(this.url, templateModel).pipe(tap(data => this.list()));
  }

  list() {
      return this.http.get<TemplateModel[]>(this.url);
  }
  get(id: number) {
    return this.http.get<TemplateModel>(`${this.url}${id}/`);
  }
  update(templateModel: TemplateModel) {
    return this.http.put<TemplateModel>(`${this.url}${templateModel.id}/`, templateModel);

  }
  delete(templateModel: TemplateModel) {
    return this.http.delete<TemplateModel>(`${this.url}${templateModel.id}/`);
  }
  getPdfFile(template_name: string, filename: string, context: any) {
    return this.http.post<Blob>(this.urlImpression,
      {
        template_name: template_name,
        file_name: filename,
        context: context,
      },
    {
      responseType: 'blob' as 'json'
    }).pipe(map(
      (data: any) => new Blob([data], {type: 'application/pdf'})));
  }

}
