import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './navbar/navbar.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreRoutingModule } from '../core/core-routing.module';
import { AcceuilComponent } from './acceuil/acceuil.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatListModule } from '@angular/material/list';
import {MenuIconComponent} from './icons/menuicon.component';
import { ComponentHostDirective } from './navbar/dynamic-core-components/component.directive';

import {AdBannerComponent} from './navbar/dynamic-core-components/component-banner.component';
import {MenuNavComponent} from './navbar/components/menu-nav.component';

import { RemoveHostDirective } from './navbar/dynamic-core-components/remove-host.directive';
import {UserNavComponent} from './navbar/components/user-nav.component';
import {AvatarModule} from '@Common/widgets/avatar/avatar.module';
import {AccessibilityModule} from "@Common/widgets/accessibility/accessibility.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreRoutingModule,
        MatSidenavModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatCardModule,
        MatTooltipModule,
        MatMenuModule,
        FlexLayoutModule,
        AvatarModule,
        AccessibilityModule
    ],
    declarations: [
        HomeComponent, NavbarComponent, AcceuilComponent, MenuIconComponent,
        AdBannerComponent,
        UserNavComponent,
        MenuNavComponent,
        ComponentHostDirective,
        RemoveHostDirective
    ],
    exports: [
        HomeComponent,
        NavbarComponent,
        AcceuilComponent,
        MenuIconComponent
    ],
    providers: []
})
export class HomeModule { }
