import { Injectable } from '@angular/core';
import {
  CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild,
  Route, CanLoad
} from '@angular/router';

import { AuthService } from './auth.service';


@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate, CanActivateChild, CanLoad {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin(state);
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
  canLoad(route: Route): boolean {
    return this.checkLogin();
  }

  checkLogin(state = null): boolean {
    if (this.authService.loggedIn()) { return true; }

    if (state) {
      this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    } else {
      this.router.navigate(['/login']);
    }

    return false;
  }


}
