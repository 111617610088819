/**
 * Created by paulguichon on 15/11/2016.
 */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';


const appRoutes: Routes = [
  { path: 'login', component: LoginComponent},

];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes )
  ],
  exports: [
    RouterModule
  ]
})
export class CoreRoutingModule {}
