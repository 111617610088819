import {
  Component, Input, AfterViewInit, ViewChild, ComponentFactoryResolver, OnDestroy,
  OnChanges, SimpleChanges
} from '@angular/core';

import { ComponentHostDirective } from './component.directive';
import { ComponentItem }      from './component-item';
import { NavComponent } from './component-interface.component';

@Component({
  selector: 'add-banner',
  template: `<ng-container host></ng-container>`,
  styles: []
})
export class AdBannerComponent implements OnDestroy, OnChanges {
  @Input() componentsItems: ComponentItem[];

  @ViewChild(ComponentHostDirective, { static: true }) host: ComponentHostDirective;
  subscription: any;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnChanges(changes: SimpleChanges) {
    this.loadComponent();
  }

  ngOnDestroy() {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  loadComponent() {
    if(this.componentsItems) {

      let viewContainerRef = this.host.viewContainerRef;
      viewContainerRef.clear();

      for (let i = 0; i < this.componentsItems.length; i++) {
        let adItem = this.componentsItems[i];

        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem.component);

        let componentRef = viewContainerRef.createComponent(componentFactory);
        (<NavComponent>componentRef.instance).data = adItem.data;
        (<NavComponent>componentRef.instance).subject = adItem.subject;
        componentRef.changeDetectorRef.detectChanges();
      }
    }
  }
}
;
