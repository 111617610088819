import {Component, Input, OnInit} from '@angular/core';
import {FavoriModel} from "../common/services/favori/favori.model";

@Component({
  selector: 'app-favoris',
  templateUrl: './favoris.component.html',
  styleUrls: ['./favoris.component.scss']
})
export class FavorisComponent implements OnInit {
  favoris: FavoriModel[] = [];
  @Input() cssClass: string;
  addTooltip = 'Ajouter des applications à vos favoris';
  constructor() { }

  ngOnInit(): void {
    this._getFavoris();
  }

  private _getFavoris() :void {
    this.favoris = [
      {user: 'mtrovisco', application: 1, application_obj: {id: 1, label: 'Helpdesk', description: 'Helpdesk : assistance, incidents et demandes DSI-N', path: '/helpdesk', logo: 'support', is_public: true, is_active: true, theme: 1},},
    ]
  }

}
