import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'p8-layout-settings-header',
  templateUrl: './settings-header.component.html',
  styleUrls: ['./settings-header.component.scss']
})
export class SettingsLayoutHeaderComponent implements OnInit {
  @Input() titlePath: string[] = [];
  @Input() iconRacine: string = '';
  @Input() separator = '>';
  @Input() card = true;

  constructor() { }

  ngOnInit() {
  }

}
