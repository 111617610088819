
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CoreModule } from '../common/core/core.module';

import { CommonModuleP8 } from '../common/common-p8.module';
import { AppRoutingModule } from './app-routing';

import { HandlerError } from '../common/handler-error';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import { AccueilComponent } from './accueil/accueil.component';
import { MatAngularModule } from '../common/mat-angular.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { LayoutModule } from '@angular/cdk/layout';
import { ApplicationsComponent } from './applications/applications.component';
import { FavorisComponent } from './favoris/favoris.component';




@NgModule({
  declarations: [
    AppComponent,
    AccueilComponent,
    DashboardComponent,
    ApplicationsComponent,
    FavorisComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    CoreModule,
    CommonModuleP8,
    MatAngularModule,
    FlexLayoutModule,
    CommonModule,
    AppRoutingModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,

  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'fr-FR'},
    {provide: ErrorHandler, useClass: HandlerError},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
