import { Injectable } from '@angular/core';
import { ReplaySubject , BehaviorSubject} from 'rxjs';
import {environment} from '../../../../../../environments/environment';
import {Entite} from './entite.model';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';


@Injectable({   providedIn: 'root' })
export class EntiteService {
  private url = `${environment.baseUrl}/gestion_universite/entite/`;
  entiteSource = new ReplaySubject<Entite[]>();
  private entiteSource2 = new ReplaySubject<Entite>(1);
  selectedComposante: Entite;
  entite$ = this.entiteSource2.asObservable();
  dataChange: BehaviorSubject<Entite[]> = new BehaviorSubject<Entite[]>([]);

  constructor(private http: HttpClient) {
  }

  create(entite: Entite) {
    return this.http.post<Entite>(this.url, JSON.stringify(entite)).pipe(tap(data => this.list()));
  }

  list() {
      return this.http.get<Entite[]>(this.url);
  }

  listByType(type: string) {
    return this.http.get<Entite[]>(`${this.url}?type_entite=${type}`);
  }

  listByParent(entiteId: number) {
    return this.http.get<Entite[]>(this.url,{params: { by_parent: entiteId.toString()}});
  }

  listByTypeAndProjetNotNull(type: string) {
    return this.http.get<Entite[]>(`${environment.baseUrl}/campagne_emplois/entite/?by_type_and_projet_not_null=${type}`);
  }
  listByParentAndType(entiteId: number, type) {
    return this.http.get<Entite[]>(this.url,{params: { by_parent_and_type: `${entiteId}:${type}`}});
  }
  filterByName(name: string) {
    return this.http.get<Entite[]>(this.url,{params: { by_name: name}});
  }
  get(id: number) {
    return this.http.get<Entite>(`${this.url}${id}/`);
  }

  update(entite: Entite) {
    return this.http.put<Entite>(`${this.url}${entite.id}/`, entite);
  }

  delete(entite: Entite) {
    return this.http.delete<Entite>(`${this.url}${entite.id}/`);
  }

  reloadData(): void {
    this.list().subscribe(data => {
      this.entiteSource.next(data as Entite[]);
    });
  }

  reloadDataByType(type: string) {
    this.listByType(type).subscribe(data => {
      this.entiteSource.next(data as Entite[]);
    });
  }

  sendEntite(composante: Entite) {
    this.entiteSource2.next(composante);
  }

  /**
   * Tri des entités
   * @param {Entite[]} entites
   * @param {string} by : proprité de tri, par défaut "name"
   */
  sortEntitesBy(entites: Entite[], by = 'name') {
    entites.sort((a: Entite, b: Entite) => (a[by] > b[by]) ? 1 : ((b[by] > a[by]) ? -1 : 0));
    return entites;
  }
}
