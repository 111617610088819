import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-portail-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.scss']
})
export class AccueilComponent implements OnInit, OnDestroy {
  cssClass = 'theme__1';

  constructor() { }

  ngOnInit() {}

  ngOnDestroy(): void {
  }

  changeClass(cssClass: string): void {
    this.cssClass = cssClass;
  }

}
