import {Component, Input, NgZone, OnDestroy, OnInit, VERSION, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {Subscription} from 'rxjs';
import {AuthService, User} from '@Services/auth';
import {Message} from '@Services/message/message.model';
import {MessageService} from '@Services/message/message.service';
import {Menu} from '../models/menu.model';
import {Router} from '@angular/router';
import {
  IndividuService,
  IndividuExterneService,
  AccessTo,
  IndividuFactoryService
} from '@Common/core/services';
import {environment} from "@Env/environment";
import {SubSink} from "@Common/core/utils/subsink";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  messages: Message[];
  opened = true;
  superUser = false;
  user: User;
  autosize = true;
  isLoggedIn = false;
  sidenavMode = 'side';
  @Input() miniSidenav = false;
  @Input() darkTheme = false;
  @Input() menu: Menu [];
  @Input() titre: string;
  @Input() version: string;
  @Input() displayLogon = true;
  @Input() displaySidenav = true;
  @Input() customNavBackground = '';
  @Input() customNavFontColor = '';
  @Input() bodyBgColor = '#faf3f8';
  /**
   * Retour vers P8, par défaut /, peut être pour la racine d'une application, par ex. helpdesk, rds, etc
   */
  @Input() homeApplication = '/';
  @Input() avatar = true;
  @Input() showAccessibility = true;
  @Input() anonymous = false;
  @Input() assetsSource;
  @ViewChild('sidenav') sidenav: MatSidenav;
  private parentUrl = '/';
  private messageSubscription: Subscription;
  private userSubscription: Subscription;

  private individuSvc: IndividuService | IndividuExterneService;
  private subSink = new SubSink();

  constructor(
    private auth: AuthService,
    private messageService: MessageService,
    private individuFactoryService: IndividuFactoryService,
    private zone: NgZone,
    private router: Router
  ) {
    console.log('version angular : ', VERSION.full);
  }

  get open() {
    return this.opened;
  }

  @Input()
  set open(value: boolean) {
    this.opened = value;
  }

  disableTooltipElementMenu(choice: Menu) {
    if ('showToolTip' in choice) {
      return !choice.showToolTip;
    }
    return !this.miniSidenav;
  }

  loggedIn(): boolean {
    if (this.anonymous) {
      if (this.auth.isTokenExpired()) {
        // logout supprimet le Token ET l'user du localstorage, voir si on garde l'user quand même ?
        this.auth.logout();
      }

      return true;
    }

    const logged = this.auth.loggedIn();
    if (!logged && this.router.url !== '/login') {
      // this.auth.redirectUrl = this.router.url;
      this.router.navigate(['/login'], {state: {parentUrl: this.parentUrl}});
    }
    if (logged !== this.isLoggedIn) {
      this.isLoggedIn = logged;
      this.autosizeSidenav();
    }
    return logged;
  }

  ngOnInit() {
    this.individuSvc = this.individuFactoryService.individuService();

    this.parentUrl = window.location.pathname;
    if (environment.env !== 'dev') {
      const pahName = window.location.pathname;
      const appName = pahName.replace('http://', '')
        .replace('https://', '')
        .split(/[/?#]/)[1];
      this.parentUrl = pahName.replace('/' + appName, '');
    }

    this.toggleSidenav({target: window});
    this.userSubscription = this.auth.userActivate$.subscribe(user => {
      console.log('home auth user ', user);
      if (user) {
        this.subSink.sink = this.individuSvc.canConnectToApplication().subscribe(
          (accessTo: AccessTo) => {
            if (!accessTo.is_active) {
              this.auth.logout();
              this.router.navigate(['/login'], {state: {parentUrl: this.parentUrl}});
            } else {
              this.user = user;
              this.superUser = user.is_superuser;
            }
          }
        )
      }
    });
    this.messageService.messageSelected$.subscribe(data => {
      /*
      Ici il y a un gros trick à comprendre :
      lors que le handlerError capte le message, il va envoyer un message, mais le handler error le fait qu'après la boucle de zone.js
      du coup la detection du changement du message ne se fait pas.
      Il faut pour cela appeler manuellement la mise à jour de la variable avec ngZone.
      Cf : https://blog.octo.com/angular-2-sse-et-la-detection-de-changements/
       */
      this.zone.run(() => this.messages = data);
    });
    this.autosizeSidenav();
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    this.subSink.unsubscribe();
  }

  closeAlert(i: number) {
    this.messageService.clearMessage(i);
  }

  toggleOpened($event: boolean) {
    this.opened = $event;
  }

  /**
   * J'active l'autosize de la sidenav, seulement quand il est necessaire, sinon recalcule le taille de la sidenac à
   * chaque boucle, ce qui cosomme trop de ressources
   */
  autosizeSidenav() {
    this.autosize = true;
    setTimeout(() => this.autosize = false, 1000);
  }

  toggleSidenav($event: any) {
    this.sidenavMode = ($event.target.innerWidth < 920) ? 'over' : 'side';
    this.opened = ($event.target.innerWidth < 920) ? false : this.opened;
    this.miniSidenav = ($event.target.innerWidth < 920) ? false : this.miniSidenav;
  }
}
