import { Injectable } from '@angular/core';
import {DaoGeneric} from '@Services/base/daoService';
import {HttpClient} from '@angular/common/http';
import {environment} from '@Env/environment';
import {ThemeModel} from "./theme.model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ThemeService extends DaoGeneric<ThemeModel> {
  private url = `${environment.baseUrl}/portail/theme/`;


  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  getRootUrl(): string {
    return this.url;
  }

  saveThemes(themes: ThemeModel[]): Observable<boolean>{
    const url = `${this.url}bulk/`;
    return this.httpClient.post<boolean>(url, themes);
  }

}
