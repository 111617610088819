import {AfterContentChecked, Directive, ElementRef, EventEmitter, Inject, Input, OnInit,} from '@angular/core';

@Directive({
  selector: '[focus]'
})
export class FocusDirective implements OnInit, AfterContentChecked {


  @Input()
  focus: boolean = false;
  // directive pour manager le focus d'un element focusable
  // pour un example d'utilisation regarder commment en helpdesk/common

  constructor(@Inject(ElementRef) private element: ElementRef) {
  }


  ngOnInit(): void {

  }

  startFocus() {
    this.element.nativeElement.focus();
  }

  ngAfterContentChecked(): void {
    if (this.focus) {
      this.startFocus();
    }
  }

}
