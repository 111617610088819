import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeModule } from './home/home.module';
import { HomeComponent } from './home/home/home.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SearchLdapComponent } from './widgets/ldap/search-ldap/search-ldap.component';
import { LdapInfoUserComponent } from './widgets/ldap/ldap-info-user/ldap-info-user.component';
import { DialogValidationComponent } from './widgets/general/dialog-validation';
import {PaginationComponent} from './widgets/general/pagination';
import {RatingComponent} from './widgets/rating/rating.component';
import {PagerService} from './widgets/general/pagination';
import {UpperCaseFirstLetterTextDirective} from '@Common/widgets/directives';

import {FocusDirective} from '@Common/widgets/directives';
import { MatAngularModule } from './mat-angular.module';

import {GetTimeWithoutSecondsPipe, RemoveDuplicatePipe, TruncatePipe} from './widgets/pipes';
import {SafeHtmlPipe} from './widgets/pipes';
import {SlugifyPipe} from './widgets/pipes';
import {TimeAgoPipe} from './widgets/pipes';
import {NewlinePipe} from './widgets/pipes';

import {DataTableComponent} from './widgets/general/data-table';

import {CKEditorCustomComponentP8} from './widgets/template-editor';
import { LoaderComponent } from './widgets/general/loader/loader.component';

import {HostViewHostDirective} from '@Common/widgets/directives/core/host-view-container.directive';

import {RemoveHostViewContainerDirective} from '@Common/widgets/directives/core/remove-host.directive';

import {DataTableFilterModaleComponent} from '@Common/widgets/general/data-table/data-table-filter-modale/data-table-filter-modale.component';
import {DataTableHeaderComponent} from '@Common/widgets/general/data-table/data-table-header-component/data-table-header.component';

import {SnackBarToastyComponent} from '@Common/widgets/general/snack-bar/snack-bar-toasty.component';

import {OperatorFilterComponent} from '@Common/widgets/filters/operator-filter/operator-filter.component';
import {SelectFilterComponent} from '@Common/widgets/filters';
import {Select2Component} from '@Common/widgets/general/select2/select2.component';
import {RoleEntitesComponent} from '@Common/widgets/general/roles/role-entites.component';

import {FileSizePipe} from '@Common/widgets/pipes/file-size';

import {OrderByPipe} from '@Common/widgets/pipes/orderBy.pipe';
import {NotInPipe} from '@Common/widgets/pipes/notIn.pipe';
// tslint:disable-next-line:max-line-length
import {DataTableCellComponentComponent} from '@Common/widgets/general/data-table/data-table-cell-component/data-table-cell-component.component';
import {TreeLazyComponent} from '@Common/widgets/general/tree-lazy/tree-lazy.component';
import {ForbiddenComponent} from '@Common/forbidden/forbidden.component';

import { AttachmentsComponent } from '@Common/widgets/general/attachments';
import { ListCommentsComponent } from './widgets/general/comments/list-comments/list-comments.component';
import {AttachmentUploadComponent} from '@Common/widgets/general/attachments/attachment-upload/attachment-upload.component';
import {QuillModule} from 'ngx-quill';
import {CommentComponent} from '@Common/widgets/general/comments/comment/comment.component';
import {DisclaimerTestComponent} from '@Common/widgets/general/p8-disclaimer-test/disclaimer-test.component';
import {EntitesComponent} from '@Common/widgets/entites/entites.component';
import {AvisEtObservationsComponent} from '@Common/widgets/avis-et-observations/avis-et-observations.component';
import {ImagepdfviewerModule} from '@Common/widgets/general/imagepdfviewer';
import {SpinnerComponent} from '@Common/widgets/general/spinner/spinner.component';
import {LoginModule} from "@Common/core/login/login.module";
import {LayoutModule} from "@Common/widgets/general/layout/layout.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatAngularModule,
    QuillModule.forRoot(),
    HomeModule, LoginModule, LayoutModule,
    ImagepdfviewerModule
  ],
  declarations: [
    SearchLdapComponent,
    LdapInfoUserComponent,
    DialogValidationComponent,
    PaginationComponent,
    RatingComponent,
    UpperCaseFirstLetterTextDirective,
    TruncatePipe,
    FocusDirective,
    SafeHtmlPipe,
    RemoveDuplicatePipe,
    SlugifyPipe,
    TimeAgoPipe,
    NewlinePipe,
    FileSizePipe,
    OrderByPipe,
    GetTimeWithoutSecondsPipe,
    DataTableComponent,
    DataTableHeaderComponent,
    DataTableCellComponentComponent,
    DataTableFilterModaleComponent,
    HostViewHostDirective,
    RemoveHostViewContainerDirective,
    CKEditorCustomComponentP8,
    LoaderComponent,
    SnackBarToastyComponent,
    OperatorFilterComponent,
    SelectFilterComponent,
    Select2Component,
    RoleEntitesComponent,
    NotInPipe,
    TreeLazyComponent,
    ForbiddenComponent,
    CommentComponent,
    AttachmentsComponent,
    AttachmentUploadComponent,
    ListCommentsComponent,
    DisclaimerTestComponent,
    EntitesComponent,
    AvisEtObservationsComponent,
    SpinnerComponent
  ],
  exports: [
    HomeComponent,
    SearchLdapComponent,
    LdapInfoUserComponent,
    DialogValidationComponent,
    PaginationComponent,
    RatingComponent,
    UpperCaseFirstLetterTextDirective,
    TruncatePipe,
    RemoveDuplicatePipe,
    FocusDirective,
    SafeHtmlPipe,
    SlugifyPipe,
    TimeAgoPipe,
    NewlinePipe,
    FileSizePipe,
    OrderByPipe,
    GetTimeWithoutSecondsPipe,
    DataTableComponent,
    DataTableHeaderComponent,
    DataTableCellComponentComponent,
    CKEditorCustomComponentP8,
    SnackBarToastyComponent,
    OperatorFilterComponent,
    SelectFilterComponent,
    Select2Component,
    RoleEntitesComponent,
    NotInPipe,
    TreeLazyComponent,
    CommentComponent,
    ListCommentsComponent,
    DisclaimerTestComponent,
    EntitesComponent,
    AvisEtObservationsComponent,
    HomeModule, LoginModule, LayoutModule,
    AttachmentsComponent,
    SpinnerComponent
  ],
    providers: [PagerService]
})
export class CommonModuleP8 {
}
