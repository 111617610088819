import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { StructureEnseignement } from './structure-enseignement.model';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class StructureEnseignementService {
  private url = `${environment.baseUrl}/gestion_universite/structure-enseignement/`;

  constructor(private http: HttpClient) {
  }

  create(structureEnseignement: StructureEnseignement) {
    return this.http.post<StructureEnseignement>(this.url, structureEnseignement).pipe(tap(data => this.list()));
  }

  list() {
    return this.http.get<StructureEnseignement[]>(this.url);
  }

  get(uid: string) {
    return this.http.get<StructureEnseignement>(`${this.url}${uid}/`);
  }

  update(structureEnseignement: StructureEnseignement) {
    return this.http.put<StructureEnseignement>(`${this.url}${structureEnseignement.uid}/`, structureEnseignement);

  }

  delete(structureEnseignement: StructureEnseignement) {
    return this.http.delete<StructureEnseignement>(`${this.url}${structureEnseignement.uid}/`);

  }

  filterByComposanteId(id: number) {
    return this.http.get<StructureEnseignement[]>(`${this.url}`, {params: {composante: `${id}`}});
  }

  filterByParentId(uid: string) {
    return this.http.get<StructureEnseignement[]>(`${this.url}`, {params: {by_parent: uid}});
  }
}
