import * as moment from "moment";
import {Injectable} from "@angular/core";
import * as FileSaver from "file-saver";

@Injectable({ providedIn: 'root' })
export class FileSaverService {
  FileSave(data: any, filename: string = null, ext = 'txt') {
    this.downloadFile(data, filename, ext);
  }

  private downloadFile(data: any, filename = null,  ext = 'txt'){
    let dte = moment().format('YYYYMMDD_HH:mm:ss');

    let nameFile = '';
    if(filename)
      nameFile = `${filename}_${dte}.${ext}`;
    else
      nameFile = `EXPORT_${dte}.${ext}`;

    FileSaver.saveAs(data, nameFile);
  }
}
