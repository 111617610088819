import {Component, OnInit, ChangeDetectionStrategy, SimpleChanges} from '@angular/core';
import { Output, Input, EventEmitter, OnChanges } from '@angular/core';
import {PagerService} from "./pager.service";



@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input()
  page: number;

  /**
   * total de tous les éléments de la liste
   * @type {number}
   */
  @Input()
  total = 0;

  /**
   * total des éléments de la vue
   * @type {number}
   */
  @Input()
  totalView = 0;

  /**
   * nb. max d'éléments par page
   * @type {number}
   */
  @Input()
  maxItems = 10;

  /**
   * afficher le nombre d'éléments / total éléments ?
   * par défaut false
   * @type {boolean}
   */
  @Input()
  displayCountPages = true;

  /**
   * liste de choix du nombre d'éléments par page
   * optionnel
   * @type {number[]}
   */
  @Input()
  numberItemsChoices: number[] = [];

  @Input()
  colorButton: string = 'primary';

  /**
   * au changement d'une page
   */
  @Output('paginationChangedPage') paginationChangedPage: EventEmitter<any>;

  /**
   * au changement du nb. max d'éléments
   */
  @Output('maxItemsChanged') maxItemsChanged: EventEmitter<any>;

  pager: any = {};
  totalPages: 0;
  selectedMaxItems = this.maxItems;

  constructor(private pagerService: PagerService) {
    this.paginationChangedPage = new EventEmitter();
    this.maxItemsChanged = new EventEmitter();
  }

  ngOnInit() {
    this.page = 1;
    this.selectedMaxItems = this.maxItems;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setPager();
  }

  setPage(page) {
    this.paginationChangedPage.emit(page);
  }

  onSelectMaxItems(event) {
    this.maxItemsChanged.emit(event.value);
  }

  private setPager(page = 1) {
    if (this.page < 1 || this.page > this.pager.totalPages) {
      return;
    }

    if (this.total) {
      this.pager = this.pagerService.getPager(this.total, this.totalView, this.page, this.maxItems);

      this.totalPages = this.pager.totalPages;
    }
  }
}
