<div class="{{cssClass}}">
  <div class="favoris">
    <mat-card>
      <div class="left">
        <!--<span class="fav-title">FAVORIS</span>-->
        <div class="icon"><span class="icon_content"><mat-icon>star</mat-icon></span></div>
      </div>
      <div class="right">
        <ng-container *ngIf="cssClass === 'theme__4__fav'">
          <button mat-raised-button matTooltip="{{addTooltip}}"><mat-icon>add</mat-icon>FAVORIS</button>
        </ng-container>
        <ng-container *ngIf="cssClass !== 'theme__4__fav'">
          <ng-container *ngFor="let favori of favoris">
            <a href="{{favori.application_obj.path}}">
              <div class="icon" matTooltip="{{favori.application_obj.label}}">
              <span class="icon_content">
                <mat-icon >{{(favori.application_obj.logo?favori.application_obj.logo:'apps')}}</mat-icon>
              </span>
              </div>
            </a>
          </ng-container>
          <a href="javascript:void(0)">
            <div class="icon add" matTooltip="{{addTooltip}}">
              <span class="icon_content">
                <mat-icon>add</mat-icon>
              </span>
            </div>
          </a>
        </ng-container>
      </div>
    </mat-card>
  </div>
</div>

